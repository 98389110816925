<template>
  <v-container>
    <v-row>
      <v-col cols="9" class="pr-1">
        <v-card
          v-if="!survey.elements[props.index].name || edit"
          elevation="5"
          class="my-4 py-1 ml-4 mr-0 pr-0"
        >
          <v-form
            ref="form"
            @submit.prevent="save"
          >
            <v-card-title class="pa-2">
              <span>Tipo Sí o No </span>
              <v-icon v-if="settings.some(ele => ele === 0)" color="primary" small>{{ icons.mdiAsterisk  }}</v-icon>
            </v-card-title>
            <v-card-text class="pa-2">
              <v-text-field
                v-model="title"
                autofocus
                label="Ingresar la pregunta aquí"
                placeholder="Ingresar la pregunta aquí"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
              ></v-text-field>
            </v-card-text>
            <v-card-text class="pa-2" v-if="settings.some(ele => ele === 1)">
              <v-text-field
                v-model="note"
                label="Ingresar la nota aquí"
                outlined
                placeholder="Ingresar la nota aquí"
                hide-details
              ></v-text-field>
            </v-card-text>
            <v-card-text class="pa-2">
              <v-text-field
                v-model="option1"
                placeholder="Opción 1"
                :prepend-icon="icons.mdiCheckboxBlankCircleOutline"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
                class="mb-1"
              ></v-text-field>
              <v-text-field
                v-model="option2"
                placeholder="Opción 2"
                :prepend-icon="icons.mdiCheckboxBlankCircleOutline"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
              ></v-text-field>
            </v-card-text>
            <v-card-text class="pa-2" v-if="settings.some(ele => ele === 2)">
              <v-textarea
                label="Comentarios"
                rows="2"
                outlined
                readonly
                hide-details
              ></v-textarea>
            </v-card-text>

            <v-card-actions class="pa-2">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                type="submit"
              >
                Guardar
              </v-btn>

              <v-btn
                color="secondary"
                text
                @click="cancel"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>

        <v-card
          v-else
          :elevation="props.hover ? '10' : '5'"
        >
          <v-card-title>
            {{survey.elements[props.index].title}}
            <v-icon v-if="survey.elements[props.index].isRequired" color="primary" small>{{ icons.mdiAsterisk  }}</v-icon>
            <v-spacer></v-spacer>
            <div v-show="props.hover">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editQuestion"
                  >
                    <v-icon small>{{icons.mdiPencilOutline}}</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="duplicateQuestion"
                  >
                  <v-icon small>{{icons.mdiContentCopy}}</v-icon>
                  </v-btn>
                </template>
                <span>Duplicar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteQuestion"
                  >
                    <v-icon small>{{icons.mdiDeleteOutline}}</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </div>
          </v-card-title>

          <v-card-title class="body-1 py-0">{{survey.elements[props.index].description}}</v-card-title>

          <v-card-text>
            <v-radio-group>
              <v-radio
                v-for="choice of survey.elements[props.index].choices"
                :label="choice"
                :key="choice"
                readonly
              >
              </v-radio>
            </v-radio-group>
          </v-card-text>

          <v-card-text v-if="survey.elements[props.index].hasComment">
            <v-textarea
              label="Comentarios"
              rows="2"
              outlined
              hide-details
              readonly
            ></v-textarea>
          </v-card-text>

        </v-card>
      </v-col>
      <v-col cols="3" class="pl-0" v-if="!survey.elements[props.index].name || edit">
        <v-card
          class="my-4 py-1 ml-4 mr-0 pr-0"
        >
          <v-list
            subheader
            flat
          >
            <v-subheader>Opciones</v-subheader>

            <v-list-item-group
              v-model="settings"
              multiple
            >
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Respuesta Obligatoria</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Añadir nota</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Añadir Comentarios</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mdiPencilOutline, mdiContentCopy, mdiDeleteOutline, mdiCheckboxBlankCircleOutline, mdiAsterisk } from '@mdi/js'
import { ref, inject, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'

export default {
  props: {
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    index: {
      type: Number,
      default: null,
      required: false,
    },
  },
  setup(props) {
    const survey = inject('survey')
    const form = ref(null)
    const settings = ref([])
    const title = ref('')
    const option1 = ref('')
    const option2 = ref('')
    const note = ref('')
    const edit = ref(false)
    option1.value = 'Si'
    option2.value = 'No'

    watch(
      [edit],
      val => {
        if (val) {
          title.value = survey.elements[props.index].title
          option1.value = survey.elements[props.index].choices[0]
          option2.value = survey.elements[props.index].choices[1]
          let sets = []
          if (survey.elements[props.index].isRequired) sets.push(0)
          if (survey.elements[props.index].hasComment) sets.push(2)
          if (survey.elements[props.index].description) {
            sets.push(1)
            note.value = survey.elements[props.index].description
          }
          settings.value = sets
        }
      },
      { deep: true },
    )

    const cancel = () => {
      if (edit.value) {
        edit.value = false
      } else {
        deleteQuestion()
      }
    }

    const clear = () => {
      settings.value = []
      option1.value = 'Si'
      option2.value = 'No'
      title.value = ''
      note.value = ''
    }

    const editQuestion = () => {
      edit.value = true
    }

    const deleteQuestion = () => {
      survey.elements.splice(props.index, 1)
    }

    const duplicateQuestion = () => {
      survey.elements.push(survey.elements[props.index])
    }

    const save = () => {
      const isFormValid = validateForm(form)
      if (!isFormValid) return
      let payload = {
        component: 'yes-no',
        type: 'radiogroup',
        title: title.value,
        name: 'yes-no' + String(props.index + 1),
        choices: [option1.value, option2.value],
      }
      if (settings.value.some(ele => ele === 1)) {
        payload.description = note.value
      } else delete payload.description

      if (settings.value.some(ele => ele === 0)) {
        payload.isRequired = true
      } else payload.isRequired = false

      if (settings.value.some(ele => ele === 2)) {
        payload.hasComment = true
        payload.commentText = 'Comentarios'
      } else {
        payload.hasComment = false
        delete payload.commentText
      }
      edit.value = false
      survey.elements.splice(props.index, 1, payload)
      clear()
    }

    return {
      cancel,
      deleteQuestion,
      duplicateQuestion,
      edit,
      editQuestion,
      form,
      note,
      option1,
      option2,
      props,
      save,
      settings,
      survey,
      title,
      validators: {
        required,
      },
      icons: {
        mdiAsterisk,
        mdiCheckboxBlankCircleOutline,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
