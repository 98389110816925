var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"9"}},[(!_vm.survey.elements[_vm.props.index].name || _vm.edit)?_c('v-card',{staticClass:"my-4 py-1 ml-4 mr-0 pr-0",attrs:{"elevation":"5"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"pa-2"},[_c('span',[_vm._v("Tipo Texto Libre")]),(_vm.settings.some(function (ele) { return ele === 0; }))?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm.icons.mdiAsterisk))]):_vm._e()],1),_c('v-card-text',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"autofocus":"","label":"Ingresar la pregunta aquí","placeholder":"Ingresar la pregunta aquí","outlined":"","hide-details":"auto","rules":[_vm.validators.required]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),(_vm.settings.some(function (ele) { return ele === 1; }))?_c('v-card-text',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"label":"Ingresar la nota aquí","outlined":"","placeholder":"Ingresar la nota aquí","hide-details":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1):_vm._e(),_c('v-card-text',[(_vm.field === 1)?_c('v-textarea',{attrs:{"label":"Texto Libre","rows":"3","outlined":"","readonly":"","hide-details":""}}):_c('v-text-field',{attrs:{"label":_vm.field === 0 ? 'Texto Libre' :
              _vm.fieldTypes.find(function (ele) { return ele.value === _vm.field; }) ?
              _vm.fieldTypes.find(function (ele) { return ele.value === _vm.field; }).text : '',"outlined":"","readonly":"","hide-details":""}})],1),_c('v-card-actions',{staticClass:"pa-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","type":"submit"}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")])],1)],1)],1):_c('v-card',{attrs:{"elevation":_vm.props.hover ? '10' : '5'}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.survey.elements[_vm.props.index].title)+" "),(_vm.survey.elements[_vm.props.index].isRequired)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm.icons.mdiAsterisk))]):_vm._e(),_c('v-spacer'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.hover),expression:"props.hover"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.editQuestion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}])},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateQuestion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiContentCopy))])],1)]}}])},[_c('span',[_vm._v("Duplicar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.deleteQuestion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}])},[_c('span',[_vm._v("Borrar")])])],1)],1),_c('v-card-title',{staticClass:"body-1 py-0"},[_vm._v(_vm._s(_vm.survey.elements[_vm.props.index].description))]),(_vm.survey.elements[_vm.props.index].type ==='comment')?_c('v-card-text',{staticClass:"pa-2"},[_c('v-textarea',{attrs:{"label":"Multi Línea","rows":"2","outlined":"","hide-details":"","readonly":""}})],1):_c('v-card-text',{staticClass:"pa-2",attrs:{"aquí":""}},[_c('v-text-field',{attrs:{"label":!_vm.survey.elements[_vm.props.index].inputType ? 'Texto Libre' : _vm.fieldTypes.find(function (ele) { return ele.value === _vm.survey.elements[_vm.props.index].inputType; }).text,"rows":"2","outlined":"","hide-details":"","readonly":""}})],1)],1)],1),(!_vm.survey.elements[_vm.props.index].name || _vm.edit)?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"my-4 py-1 ml-4 mr-0 pr-0"},[_c('v-list',{attrs:{"subheader":"","flat":""}},[_c('v-subheader',[_vm._v("Opciones")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Respuesta Obligatoria")])],1)]}}],null,false,3761914527)}),_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Añadir nota")])],1)]}}],null,false,2590366280)})],1)],1)],1),_c('v-card',{staticClass:"my-4 py-1 ml-4 mr-0 pr-0"},[_c('v-select',{attrs:{"outlined":"","label":"Tipo de Campo","color":"primary","items":_vm.fieldTypes},model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }