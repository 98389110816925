<template>
  <div class="page-title text-center px-5 pt-3" v-if="!loadingOne">
    <dialog-show-survey v-model="showSurvey" v-if="showSurvey"></dialog-show-survey>
    <div class="d-flex pb-2">
      <div>
        <v-btn icon @click="close" >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
      </div>
      <p class="text-2xl font-weight-bold text--primary mx-auto my-auto">
        {{Object.keys($route.params).length === 0 ? 'Creador de Encuesta' : 'Editor de Encuestas'}}
      </p>
    </div>
    <v-form ref="form" @submit.prevent="submit">
      <v-card class="pa-3 mb-2">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="code"
              label="Código"
              hide-details="auto"
              :rules="[validators.required]"
              outlined
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="survey.title"
              label="Título de la Encuesta"
              placeholder="Coloque un título"
              hide-details="auto"
              :rules="[validators.required]"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-4 mb-4">
        <v-row>
          <v-col cols="2" class="pa-0">
            <v-card>
              <v-toolbar
                color="primary"
                dark
                flat
                dense
              >
                <v-toolbar-title  class="white--text ml-3">Preguntas</v-toolbar-title>
              </v-toolbar>
                <v-list>
                  <Container
                    behaviour="copy"
                    group-name="1"
                    drag-class="opacity-ghost"
                    drop-class="opacity-ghost-drop"
                    :get-child-payload="getChildPayload"
                  >
                    <draggable v-for="(type, index) in types" :key="type.title">
                      <v-list-item
                        :key="type.title"
                        class="cursor"
                      >

                        <v-list-item-content class="pt-0">
                          <v-list-item-avatar >
                            <v-icon
                              color="primary"
                            >
                              {{type.icon}}
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title v-text="type.title"></v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>
                      <v-divider
                        v-if="index < types.length - 1"
                        :key="index"
                      ></v-divider>
                    </draggable>
                  </Container>
                </v-list>
            </v-card>
          </v-col>

          <v-col cols="10" class="pa-0">
            <Container
              behaviour="contain"
              group-name="1"
              @drop="onColumnDrop"
              class="box"
              :class="survey.elements.length === 0 ? 'border': ''"
            >
              <div>
                <Container @drop="onDrop" drag-handle-selector=".column-drag-handle">
                  <Draggable
                    v-for="(questions, i) of survey.elements"
                    :key="questions.component + i"
                  >
                    <v-hover
                      v-slot="{ hover }"
                    >
                      <component class="column-drag-handle" :is="questions.component" :hover="hover" :index="i"></component>
                    </v-hover>
                  </Draggable>
                </Container>
              </div>
            </Container>
          </v-col>
        </v-row>
        <v-row
          v-if="survey.elements.length && survey.elements.some(ele => ele.name)"
          justify="center"
          class="mt-5 mb-5"
        >
          <v-col
            cols="12"
          >
            <v-btn
              color="info"
              @click="show"
              class="mx-2"
            >
            Vista Previa
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
            >
              Guardar
            </v-btn>
            <v-btn
              @click="reset"
              outlined
              class="mx-2"
            >
              Limpiar
            </v-btn>
            <v-btn
              @click="close"
              color="secondary"
              class="mx-1"
            >
              Retornar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { ref, reactive, provide } from '@vue/composition-api'
import { Container, Draggable } from 'vue-dndrop'
import { useRouter } from '@core/utils'
import {
  mdiListStatus,
  mdiText,
  mdiFormatListCheckbox,
  mdiArrowLeft,
  mdiDotsGrid,
  mdiFormatListNumbered,
  mdiPodium,
} from '@mdi/js'
import YesNo from './questions/YesNo'
import FreeText from './questions/FreeText'
import MultipleChoice from './questions/MultipleChoice'
import Ranking from './questions/Ranking'
import Rating from './questions/Rating'
import Matrix from './questions/Matrix'
import DialogShowSurvey from './survey-show/DialogShow.vue'
import { required } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'
import useSurvey from './useSurvey'

export default {
  name: 'SurveyCreator',
  components: { Container, Draggable, YesNo, FreeText, MultipleChoice, Ranking, Rating, Matrix, DialogShowSurvey },
  setup() {
    const survey = reactive({
      title: '',
      completeText: 'Enviar',
      elements: [],
    })
    const { router } = useRouter()
    const { createSurvey, fetchOne, updateSurvey } = useSurvey()
    provide('survey', survey)
    const showSurvey = ref(false)
    const code = ref('')
    const loading = ref(false)
    const loadingOne = ref(false)
    const form = ref(null)
    const id = ref(0)
    provide('showSurvey', showSurvey)
    let types = [
      {
        title: 'Si o No',
        icon: mdiListStatus,
      },
      {
        title: 'Texto Libre',
        icon: mdiText,
      },
      {
        title: 'Opción Múltiple',
        icon: mdiFormatListCheckbox,
      },
      {
        title: 'Matriz',
        icon: mdiDotsGrid,
      },
      {
        title: 'Ranking',
        icon: mdiFormatListNumbered,
      },
      {
        title: 'Escala Rating',
        icon: mdiPodium,
      },
    ]

    if (Object.keys(router.currentRoute.params).length !== 0) {
      loadingOne.value = true
      fetchOne(router.currentRoute.params.id)
        .then(data => {
          let res = data.object
          code.value = res.code
          let structure = JSON.parse(res.structure_survey)
          survey.title = structure.title
          survey.elements = structure.elements
          id.value = res.id
          loadingOne.value = false
        })
        .catch(() => (loadingOne.value = false))
    }

    const show = () => {
      showSurvey.value = true
    }

    const submit = async () => {
      try {
        const isFormValid = validateForm(form)
        if (!isFormValid) return
        let payload = {
          code: code.value,
          description: survey.title,
          stated_at: true,
          structure_survey: JSON.stringify(survey),
        }
        loading.value = true

        if (Object.keys(router.currentRoute.params).length === 0) {
          await createSurvey(payload)
          loading.value = false
          close()
        } else {
          payload.id = id.value
          await updateSurvey(payload)
          loading.value = false
          close()
        }
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    const reset = () => {
      code.value = ''
      survey.title = ''
      survey.elements = []
    }

    const close = () => {
      router.push('/encuesta/lista')
      reset()
    }

    const onColumnDrop = ({ payload }) => {
      let components = ['yes-no', 'free-text', 'multiple-choice', 'matrix', 'ranking', 'rating']
      let component = components[payload]
      survey.elements.push({ component })
    }

    const applyDrag = (arr, dragResult) => {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      let result = [...arr]

      const tmp = result[removedIndex]
      result[removedIndex] = result[addedIndex]
      result[addedIndex] = tmp
      return result
    }

    const onDrop = dropResult => {
      survey.elements = applyDrag(survey.elements, dropResult)
    }

    const getChildPayload = i => {
      return i
    }

    return {
      close,
      code,
      form,
      getChildPayload,
      loading,
      loadingOne,
      onColumnDrop,
      onDrop,
      reset,
      show,
      showSurvey,
      submit,
      survey,
      types,

      icons: {
        mdiText,
        mdiArrowLeft,
      },
      validators: {
        required,
      },
    }
  },
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}

.opacity-ghost {
  background-color: rgba(214, 67, 72, 0.2);
}

.box {
  width: 100%;
  height: 100%;
  margin-left: 4px;
  margin-right: 4px;
}

.border {
  outline: 1px dashed rgba(214, 67, 72);
}
</style>
