<template>
  <v-container>
    <v-row>
      <v-col cols="9" class="pr-1">
        <v-card
          v-if="!survey.elements[props.index].name || edit"
          elevation="5"
          class="my-4 py-1 ml-4 mr-0 pr-0"
        >
          <v-form
            ref="form"
            @submit.prevent="save"
          >
            <v-card-title class="pa-2">
              <span>Tipo Opción Múltiple </span>
              <v-icon v-if="settings.some(ele => ele === 0)" color="primary" small>{{ icons.mdiAsterisk  }}</v-icon>
            </v-card-title>
            <v-card-text class="pa-2">
              <v-text-field
                v-model="title"
                autofocus
                label="Ingresar la pregunta aquí"
                placeholder="Ingresar la pregunta aquí"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
              ></v-text-field>
            </v-card-text>
            <v-card-text class="pa-2" v-if="settings.some(ele => ele === 1)">
              <v-text-field
                v-model="note"
                label="Ingresar la nota aquí"
                outlined
                placeholder="Ingresar la nota aquí"
                hide-details
              ></v-text-field>
            </v-card-text>
            <v-card-text class="pa-2">
              <v-text-field
                v-for="(option, index) of options"
                :key="`Opcion ${index + 1}`"
                v-model="option.value"
                :placeholder="`Opcion ${index + 1}`"
                :prepend-icon="icons.mdiCheckboxBlankCircleOutline"
                :append-icon="icons.mdiDeleteOutline"
                @click:append="deleteOption(index)"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
                class="mb-1"
              ></v-text-field>
              <div class="d-flex align-center" v-if="settings.some(ele => ele === 3)">
                <span>Otro</span>
                <v-text-field
                  :prepend-icon="icons.mdiCheckboxBlankCircleOutline"
                  readonly
                  hide-details
                  outlined
                  class="mb-1 mx-1"
                ></v-text-field>
              </div>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="addOption"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>Añadir Opción</span>
              </v-tooltip>
            </v-card-text>
            <v-card-text class="pa-2" v-if="settings.some(ele => ele === 2)">
              <v-textarea
                label="Comentarios"
                rows="2"
                outlined
                readonly
                hide-details
              ></v-textarea>
            </v-card-text>

            <v-card-actions class="pa-2">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                type="submit"
              >
                Guardar
              </v-btn>

              <v-btn
                color="secondary"
                text
                @click="cancel"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>

        <v-card
          v-else
          :elevation="props.hover ? '10' : '5'"
        >
          <v-card-title>
            {{survey.elements[props.index].title}}
            <v-icon v-if="survey.elements[props.index].isRequired" color="primary" small>{{ icons.mdiAsterisk  }}</v-icon>
            <v-spacer></v-spacer>
            <div v-show="props.hover">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editQuestion"
                  >
                    <v-icon small>{{icons.mdiPencilOutline}}</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="duplicateQuestion"
                  >
                  <v-icon small>{{icons.mdiContentCopy}}</v-icon>
                  </v-btn>
                </template>
                <span>Duplicar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteQuestion"
                  >
                    <v-icon small>{{icons.mdiDeleteOutline}}</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </div>
          </v-card-title>

          <v-card-title class="body-1 py-0">{{survey.elements[props.index].description}}</v-card-title>

          <v-card-text v-if="survey.elements[props.index].type === 'radiogroup'">
            <v-radio-group>
              <v-radio
                v-for="(choice, index) of survey.elements[props.index].choices"
                :label="choice"
                :key="`Opcion ${index + 1}`"
                readonly
              >
              </v-radio>
            </v-radio-group>
          </v-card-text>

          <v-card-text v-if="survey.elements[props.index].type === 'checkbox'">
            <v-checkbox
              v-for="(choice, index) of survey.elements[props.index].choices"
              :label="choice"
              :key="`Opcion ${index + 1}`"
              readonly
              class="ma-1"
            >
            </v-checkbox>
          </v-card-text>

          <v-card-text v-if="survey.elements[props.index].type === 'dropdown'">
            <v-select
              label="Lista de opciones"
              :items="survey.elements[props.index].choices"
              readonly
            >
            </v-select>
          </v-card-text>

          <v-card-text v-if="survey.elements[props.index].hasComment">
            <v-textarea
              label="Comentarios"
              rows="2"
              outlined
              hide-details
              readonly
            ></v-textarea>
          </v-card-text>

        </v-card>
      </v-col>
      <v-col cols="3" class="pl-0" v-if="!survey.elements[props.index].name || edit">
        <v-card
          class="my-4 py-1 ml-4 mr-0 pr-0"
        >
          <v-list
            subheader
            flat
          >
            <v-subheader>Opciones</v-subheader>

            <v-list-item-group
              v-model="settings"
              multiple
            >
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Respuesta Obligatoria</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Añadir nota</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Añadir Comentarios</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Añadir opción "Otro"</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
        <v-card  class="my-4 py-1 ml-4 mr-0 pr-0">
          <v-select
            v-model="field"
            outlined
            label="Tipo de campo de opción"
            color="primary"
            :items="fieldTypes"
            class="my-1"
          ></v-select>
          <v-select
            v-if="field === 'checkbox'"
            v-model="column"
            outlined
            label="Máxima opciones a seleccionar"
            color="primary"
            :items="['No Aplica', 2, 3, 4, 5]"
          ></v-select>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {
  mdiPencilOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiCheckboxBlankCircleOutline,
  mdiAsterisk,
  mdiPlus,
} from '@mdi/js'
import { ref, inject, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'

export default {
  props: {
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    index: {
      type: Number,
      default: null,
      required: false,
    },
  },
  setup(props) {
    const survey = inject('survey')
    const form = ref(null)
    const settings = ref([])
    const title = ref('')
    const options = ref([])
    options.value.push({ value: '' })
    options.value.push({ value: '' })
    const note = ref('')
    const edit = ref(false)
    const field = ref('')
    const column = ref('No Aplica')
    let fieldTypes = [
      { text: 'Radio Button', value: 'radiogroup' },
      { text: 'Lista (una opción)', value: 'dropdown' },
      { text: 'Check Box', value: 'checkbox' },
    ]
    field.value = 'radiogroup'

    watch(
      [edit],
      val => {
        if (val) {
          title.value = survey.elements[props.index].title
          let sets = []
          if (survey.elements[props.index].isRequired) sets.push(0)
          if (survey.elements[props.index].description) {
            sets.push(1)
            note.value = survey.elements[props.index].description
          }
          if (survey.elements[props.index].hasComment) sets.push(2)
          if (survey.elements[props.index].hasOther) {
            sets.push(3)
          }
          settings.value = sets
          if (survey.elements[props.index].maxSelectedChoices) {
            column.value = survey.elements[props.index].maxSelectedChoices
          }
          field.value = survey.elements[props.index].type
          options.value = survey.elements[props.index].choices.map(element => {
            let ele = { value: element }
            return ele
          })
        }
      },
      { deep: true },
    )

    const addOption = () => {
      options.value.push({ value: '' })
    }

    const cancel = () => {
      if (edit.value) {
        edit.value = false
      } else {
        deleteQuestion()
      }
    }

    const clear = () => {
      settings.value = []
      field.value = 'radiogroup'
      title.value = ''
      note.value = ''
      options.value = []
      column.value = 'No Aplica'
    }

    const editQuestion = () => {
      edit.value = true
    }

    const deleteQuestion = () => {
      survey.elements.splice(props.index, 1)
    }

    const deleteOption = i => {
      if (options.value.length > 2) {
        options.value.splice(i, 1)
      }
    }

    const duplicateQuestion = () => {
      survey.elements.push(survey.elements[props.index])
    }

    const save = () => {
      const isFormValid = validateForm(form)
      if (!isFormValid) return
      let payload = {
        component: 'multiple-choice',
        title: title.value,
        name: 'multiple-choice' + String(props.index + 1),
        choices: options.value.map(ele => {
          return ele.value
        }),
      }
      /*  if (settings.value.some(ele => ele === 0)) {
        payload.note = note.value
      } */
      if (settings.value.some(ele => ele === 0)) {
        payload.isRequired = true
      }

      if (settings.value.some(ele => ele === 3)) {
        payload.hasOther = true
        payload.otherText = 'Otro (describe)'
      }

      if (settings.value.some(ele => ele === 1)) {
        payload.description = note.value
      } else delete payload.description

      if (options.value.length <= 6) {
        payload.colCount = 1
      } else if (options.value.length < 6 && options.value.length <= 12) {
        payload.colCount = 2
      } else if (options.value.length < 12 && options.value.length <= 18) {
        payload.colCount = 3
      } else payload.colCount = 4

      payload.type = field.value

      if (column.value !== 'No Aplica' && field.value === 'checkbox') {
        payload.maxSelectedChoices = column.value
      } else payload.maxSelectedChoices = 0

      if (settings.value.some(ele => ele === 2)) {
        payload.hasComment = true
        payload.commentText = 'Comentarios'
      } else {
        payload.hasComment = false
        delete payload.commentText
      }

      /*  if (settings.value.some(ele => ele === 2)) {
        payload.comments = true
      } */
      edit.value = false
      survey.elements.splice(props.index, 1, payload)
      clear()
    }

    return {
      addOption,
      cancel,
      column,
      deleteQuestion,
      deleteOption,
      duplicateQuestion,
      edit,
      editQuestion,
      field,
      fieldTypes,
      form,
      note,
      options,
      props,
      save,
      settings,
      survey,
      title,
      validators: {
        required,
      },
      icons: {
        mdiAsterisk,
        mdiCheckboxBlankCircleOutline,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },
}
</script>
