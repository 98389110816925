import store from '@/store'

export default function useSurvey() {

  const createSurvey = async payload => {
    try {
      const response = await store.dispatch('surveys/createSurvey', payload)
      return response
    } catch (error) {

    }
  }
  const updateSurvey = async payload => {
    try {
      const response = await store.dispatch('surveys/updateSurvey', payload)
      return response
    } catch (error) {

    }
  }

  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('surveys/fetchOne', id)
        return res
    } catch (error) {
    }
  }

  return {
    createSurvey,
    fetchOne,
    updateSurvey
  }

}
